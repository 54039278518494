































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/layouts/Layout.vue';
import Localization from '@/services/localization';
import { mapGetters } from 'vuex';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import { saveAs } from 'file-saver';

@Component({
    components: {
        Layout
    },
    computed: mapGetters(['getLocation'])
})
export default class Reports extends Localization {
    private langugeSelect: any = [];
    private currentLang: any = '';
    private dates: any = '';


    private clearReport() {
        this.dates = '';
    }

    private dateChange(event: any) {
        this.dates = event;
    }

    private downloadReport() {
        const report = {
            startDate: this.dates[0],
            endDate: this.dates[1]
        };
        this.$store.dispatch('getReportsData', report).then((res: any) => {
            window.open(res.data, '_blank');
        })
            .catch((err: any) => {
                console.error('Error', err);
            });
    }
}
