













































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Layout from '@/layouts/Layout.vue';
import i18next from 'i18next';

@Component({
  components: {
    Layout
  },
  computed: mapGetters(['getStaff'])
})
export default class SettingsUserManagement extends Vue {

  private mounted() {
    this.$store.dispatch('getPractice');
  }

  private addUser(staffUser: any) {
    this.$router.push({ path: '/settings/user-add' });
  }

  private editUser(staffUser: any) {
    this.$router.push({ name: 'user-add', params: {staffUser} });
  }

  private deleteUser(staffUser: any) {
    this.$store.dispatch('deleteStaffUser', staffUser);
  }

  private staffName(staffUser: any) {
    return `${staffUser.firstName} ${staffUser.lastName}`;
  }

  private staffType(staffUser: any) {
    switch (staffUser.role) {
      case 'practiceAdmin':
        return i18next.t('settings.user.ADMIN');
        case 'staff':
          return i18next.t('settings.user.STAFF');
      default:
        break;
    }
  }
}
